<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{$vuetify.lang.t('$vuetify.equipmentTemplate.label')}}</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form
          ref="form"
        >
          <v-row>

            <v-col
              cols="4"
            >
              <v-select
                v-model="form.category"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.category')"
                :items="categoryItems"
                :rules="rules.category"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.name"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.name')"
                :rules="rules.name"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.no"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.no')"
                :rules="rules.no"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.company"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.company')"
                :rules="rules.company"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.companyAddr"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.companyAddr')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.companyTel"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.companyTel')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-select
                v-model="form.type"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.type')"
                :items="typeItems"
                :rules="rules.type"
                item-text="paramName"
                item-value="id"
              ></v-select>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.regid"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.regid')"
              ></v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.serviceDate"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.serviceDate')"
              >
                <template v-slot:append>
                  <span
                    class="grey--text"
                  >
                    {{$vuetify.lang.t('$vuetify.common.month')}}
                  </span>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.rental"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.rental')"
              >
                <template v-slot:append>
                  <span
                    class="grey--text"
                  >
                    {{$vuetify.lang.t('$vuetify.common.yuan') + '/' + $vuetify.lang.t('$vuetify.common.month')}}
                  </span>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.deposit"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.deposit')"
              >
                <template v-slot:append>
                  <span
                    class="grey--text"
                  >
                    {{$vuetify.lang.t('$vuetify.common.yuan')}}
                  </span>
                </template>
              </v-text-field>
            </v-col>

            <v-col
              cols="4"
            >
              <v-text-field
                v-model="form.price"
                :label="$vuetify.lang.t('$vuetify.equipmentTemplate.price')"
              >
                <template v-slot:append>
                  <span
                    class="grey--text"
                  >
                    {{$vuetify.lang.t('$vuetify.common.yuan')}}
                  </span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';

  export default {
    props: {
      editStatus: {
        type: Boolean,
        default: () => false
      },
      editItem: {
        type: Object,
        default: () => null
      }
    },
    data() {
      return {
        birthdayPickerMenu: false,
        categoryItems: [
          {
            name: this.$vuetify.lang.t('$vuetify.equipmentCategory.leasingAndSale'),
            value: 'LEASING_AND_SALE'
          },
          {
            name: this.$vuetify.lang.t('$vuetify.equipmentCategory.leasing'),
            value: 'LEASING'
          },{
            name: this.$vuetify.lang.t('$vuetify.equipmentCategory.sale'),
            value: 'SALE'
          }
        ],
        typeItems: [],
        form: {
          id: null,
          category: null,
          type: null,
          name: null,
          no: null,
          company: null,
          companyAddr: null,
          companyTel: null,
          regid: null,
          serviceDate: null,
          rental: null,
          deposit: null,
          price: null
        },
        rules: {
          category: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.equipmentTemplate.category')])
          ],
          name: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.equipmentTemplate.name')])
          ],
          no: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.equipmentTemplate.no')])
          ],
          company: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.equipmentTemplate.company')])
          ],
          type: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.equipmentTemplate.type')])
          ]
        }
      }
    },

    computed: {
      ...mapGetters({
        paramInfos: 'paramInfo/paramInfos'
      })
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions({
        queryAllByTypeCode: 'paramInfo/queryAllByTypeCode'
      }),

      initialize () {
        if (this.editStatus) {
          for (let p in this.form) {
            if (p in this.editItem) {
              this.form[p] = this.editItem[p]
            } else {
              this.form[p] = null
            }
          }
        } else {
          this.resetFrom()
        }
        this.queryAllByTypeCode('EquipmentType').then( () => {
          this.typeItems = JSON.parse(JSON.stringify(this.paramInfos))
        })
      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.form.category = 'LEASING_AND_SALE'
        this.$nextTick ( () => {
          this.$refs.form.resetValidation()
        })

      },
      handleCancel() {
        this.$emit('cancel', true)
      },
      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          if (this.editStatus) {
            this.$emit('update', data);
          } else {
            this.$emit('submit', data);
          }
        }
      }
    }
  }
</script>
